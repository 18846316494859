import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import {
  Divider,
  TextField,
  Button,
  MenuItem,
  Typography,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Box,
} from '@mui/material';
import { Timestamp } from 'firebase/firestore';

const BookingForm = ({ testPackage }) => {
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    gender: '',
    phoneNumber: '',
    email: '',
    testPackage: '',
    timeSlot: '',
    address: '',
    selectedDate: '', // State for selected date
  });

  const [error, setError] = useState('');
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [generatedBookingId, setGeneratedBookingId] = useState('');
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');

  const handleDateChange = (e) => {
    const date = e.target.value;
    setFormData({ ...formData, selectedDate: date });

    // Calculate available time slots for the selected date
    const slots = [];
    for (let hour = 6; hour <= 18; hour++) {
      const isPM = hour >= 12; // Determine if it's PM
      const formattedHour = hour % 12 || 12; // Convert to 12-hour format
      const timeString = `${formattedHour}:00 ${isPM ? 'PM' : 'AM'}`; // Format hours as HH:MM AM/PM
      slots.push(timeString);
    }
    setAvailableTimeSlots(slots); // Set available time slots
  };

  const handleTimeSlotSelect = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
    setFormData({ ...formData, timeSlot }); // Update the form data with selected time slot
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setError(''); // Clear error on change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Basic phone number validation
    if (!/^\d{10}$/.test(formData.phoneNumber)) {
      setError('Phone number must be 10 digits');
      return;
    }

    if (!emailRegex.test(formData.email)) {
      setError('Invalid email format');
      return;
    }

    // Generate booking ID
    const bookingId = generateBookingId();

    try {
      // Save the booking data to Firestore with generated bookingId
      await addDoc(collection(db, 'bookings'), {
        ...formData,
        bookingId: bookingId, // Store the booking ID
        createdAt: Timestamp.now(),
      });

      // Show success dialog with booking ID
      setGeneratedBookingId(bookingId);
      setSuccessDialogOpen(true);

      // Reset form
      setFormData({
        name: '',
        age: '',
        gender: '',
        phoneNumber: '',
        email: '',
        testPackage: '',
        timeSlot: '',
        address: '',
        selectedDate: '',
      });
      setAvailableTimeSlots([]);
      setSelectedTimeSlot('');
    } catch (error) {
      console.error('Error adding document: ', error);
      setError('Failed to submit booking, please try again.');
    }
  };

  const handleDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  // Function to generate a booking ID
  const generateBookingId = () => {
    const randomCode = Math.floor(10000 + Math.random() * 90000); // 5-digit random number
    return `SBDC${randomCode}`;
  };

  return (
    <Container maxWidth="sm">
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          margin="normal"
        />

        <TextField
          fullWidth
          label="Age"
          name="age"
          type="number"
          value={formData.age}
          onChange={handleChange}
          required
          margin="normal"
          inputProps={{ min: 0 }} // Ensure age is non-negative
        />

        <TextField
          fullWidth
          label="Gender"
          name="gender"
          select
          value={formData.gender}
          onChange={handleChange}
          margin="normal"
        >
          <MenuItem value="">
            <em>Select Gender</em>
          </MenuItem>
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </TextField>

        <TextField
          fullWidth
          label="Phone Number"
          name="phoneNumber"
          type="tel"
          value={formData.phoneNumber}
          onChange={handleChange}
          required
          margin="normal"
          inputProps={{ maxLength: 10 }} // Limit input length
        />

        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
          margin="normal"
          error={!!error} // Show error state
          helperText={error} // Display error message
        />

        <TextField
          fullWidth
          label="Test Package"
          name="testPackage"
          select
          value={formData.testPackage}
          onChange={handleChange}
          required
          margin="normal"
        >
          <MenuItem value={testPackage}>
            <em>Select Test Package</em>
          </MenuItem>
          <MenuItem value="Basic Health Checkup">Basic Health Checkup</MenuItem>
          <MenuItem value="Advanced Health Checkup">Advanced Health Checkup</MenuItem>
          <MenuItem value="Custom Health Checkup">Custom Health Checkup</MenuItem>
        </TextField>

        {/* Date Selection */}
        <TextField
          fullWidth
          label="Select Date"
          type="date"
          value={formData.selectedDate}
          onChange={handleDateChange}
          required
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />

        {/* Time Slot Selection */}
        <Box sx={{ display: 'column', flexWrap: 'wrap', mt: 2 }}>
          {availableTimeSlots.length > 0 && <Typography sx={{marginBottom: 1}}>Select Time Slot</Typography>}
          {availableTimeSlots.map((slot) => (
            <Chip
              key={slot}
              label={slot}
              onClick={() => handleTimeSlotSelect(slot)}
              variant={selectedTimeSlot === slot ? 'filled' : 'outlined'}
              sx={{
                margin: 0.5,
                cursor: 'pointer',
                bgcolor: selectedTimeSlot === slot ? '#001F3F' : 'transparent', // Custom color for selected chip
                color: selectedTimeSlot === slot ? 'white' : 'inherit', // Text color for the selected chip
                borderColor: selectedTimeSlot === slot ? 'transparent' : 'rgba(0, 0, 0, 0.12)', // Border color for the outlined chip
                '&:hover': {
                  bgcolor: selectedTimeSlot === slot ? '#001F3F' : 'rgba(0, 0, 0, 0.04)', // Hover effect
                },
              }}
            />
          ))}
        </Box>

        <TextField
          fullWidth
          label="Address"
          name="address"
          value={formData.address}
          onChange={handleChange}
          required
          margin="normal"
        />

        <Button type="submit" variant="contained" style={{ backgroundColor: '#001F3F', color: 'white' }} fullWidth>
          Submit
        </Button>
      </form>

      {/* Success Dialog */}
      <Dialog open={successDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Booking Confirmed!</DialogTitle>
        <Divider sx={{ my: 1 }} />
        <DialogContent>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Booking ID: {generatedBookingId}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            Please save this Booking ID. You will need it to check your report status in the future.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">OK</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default BookingForm;
