import React, { useState } from 'react';
import '../styles/AppBar.css';
import logo from '../assets/logo.png'; // Your logo image path
import { FaQuestionCircle } from 'react-icons/fa'; // Import help icon
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Divider } from '@mui/material';

const AppBar = () => {
  const [helpDialogOpen, setHelpDialogOpen] = useState(false); // State for help dialog

  const handleHelpClick = () => {
    setHelpDialogOpen(true); // Open the help dialog
  };

  const handleCloseHelpDialog = () => {
    setHelpDialogOpen(false); // Close the help dialog
  };

  return (
    <header className="app-bar">
      <div className="app-bar-content">
        <img src={logo} alt="Medi-Home Logo" className="app-logo" />
        <div className="app-title">
          <h1>Medi-Home Lab</h1>
          <p>Med-Lab Test Made Easy</p>
        </div>
        <FaQuestionCircle className="help-icon" onClick={handleHelpClick} />
      </div>

      {/* Help Dialog */}
      <Dialog open={helpDialogOpen} onClose={handleCloseHelpDialog}>
        <DialogTitle>Contact Medi-Home Lab</DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
          </Typography>
          <Typography variant="body1" align="center">
            <a href="tel:+919663804229">
              +91 9663804229
            </a>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHelpDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </header>
  );
};

export default AppBar;
