import React, { useState } from 'react';
import { Container, Typography, Card, CardContent, CardActions, Button, Grid, Box, Divider } from '@mui/material';
import AppBar from './AppBar'; // Reuse existing AppBar component
import basicHealthImg from '../assets/basic.jpg'; // Sample images for packages
import advancedHealthImg from '../assets/advanced.jpg';
import covidTestImg from '../assets/custom.jpg';
import '../styles/Home.css'; // Custom CSS for background image and glass effect
import BookNow from './BookNow';

const packages = [
    {
        id: 1,
        title: 'Basic Health Checkup',
        description: 'A comprehensive package that covers routine health tests',
        price: '₹999',
        image: basicHealthImg,
    },
    {
        id: 2,
        title: 'Advanced Health Checkup',
        description: 'Includes advanced tests for a deeper look into your health.',
        price: '₹1999',
        image: advancedHealthImg,
    },
    {
        id: 3,
        title: 'Custom Health Checkup',
        description: 'Choose only required tests with home sample collection.',
        price: '₹499',
        image: covidTestImg,
    },
];

const Home = () => {

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("");

    const handleClickOpen = (cardTitle) => {
        setOpen(true);
        setTitle(cardTitle);
    };

    const handleClose = () => {
        setOpen(false);
        setTitle("");
    };

    return (
        <div className="home-container">
            <AppBar /> {/* Reuse existing AppBar component */}

            {/* Background Image for Home Component */}
            <div className="home-bg">
                <Container sx={{ mt: 4, color: 'white' }}>
                    {/* Glass Effect for Medi-Home Section */}
                    <Box className="glass-box" sx={{ mb: 4 }}>
                        <Typography variant="h4" align="center" gutterBottom>
                            Welcome to Medi-Home Lab
                        </Typography>
                        <Typography variant="h6" align="center" gutterBottom>
                            Why Medi-Home Lab?
                        </Typography>
                        <Typography align="center" sx={{ mb: 4, maxWidth: '600px', margin: '0 auto' }}>
                            Medi-Home Lab provides reliable, convenient, and affordable healthcare services right at your doorstep. We offer
                            home sample collection for medical tests with fast and accurate results.
                        </Typography>
                    </Box>

                    {/* Glass Effect for Services Section */}
                    <Box className="glass-box" sx={{ mb: 4 }}>
                        <Typography variant="h6" align="center" gutterBottom>
                            Our Services
                        </Typography>
                        <Typography align="center" sx={{ mb: 4, maxWidth: '600px', margin: '0 auto' }}>
                            We offer a variety of health checkup packages, from basic to advanced, tailored to meet your needs. You can
                            easily book tests online and get your samples collected at home.
                        </Typography>
                    </Box>

                    {/* How It Works Section */}
                    <Box className="glass-box" sx={{ mb: 4 }}>
                        <Typography variant="h6" align="center" gutterBottom>
                            How It Works
                        </Typography>
                        <Typography align="center" sx={{ mb: 4, maxWidth: '600px', margin: '0 auto' }}>
                            1. Select a package and click "Book Now" to initiate the booking.<br/>
                            2. Our agent will contact you to confirm the booking and provide payment instructions.<br/>
                            3. After payment, a certified agent will collect your sample at your convenience.<br/>
                            4. Receive your results online or offline within 5 to 24 hours after sample collection.
                        </Typography>
                    </Box>

                    {/* Packages Section */}
                    <Box className="glass-box" sx={{ mb: 4 }}>
                        <Typography variant="h5" align="center" gutterBottom>
                            Our Packages
                        </Typography>
                    </Box>
                    <Grid container spacing={4} justifyContent="center">
                        {packages.map((pkg) => (
                            <Grid item xs={12} sm={6} md={4} key={pkg.id}>
                                <Card
                                    sx={{
                                        minHeight: '350px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        boxShadow: '0 6px 10px rgba(0,0,0,0.1)',
                                        transition: 'transform 0.3s ease',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                        },
                                    }}
                                >
                                    <Box component="img" src={pkg.image} alt={pkg.title} sx={{ height: '150px', width: '100%', objectFit: 'cover' }} />
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            {pkg.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {pkg.description}
                                        </Typography>
                                        <Typography variant="h6" sx={{ mt: 2 }}>
                                            {pkg.price}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#001F3F', color: 'white' }}
                                            fullWidth
                                            onClick={() => handleClickOpen(pkg.title)}
                                        >
                                            Book Now
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Contact Section */}
                    <Box className="glass-box" sx={{ mt: 5, textAlign: 'center' }}>
                        <Typography>Contact</Typography>
                        <Divider />
                        <Typography
                            onClick={() => (window.location.href = 'tel:+919663804229')}
                        >
                            Phone: +91 9663804229
                        </Typography>
                        <Divider />
                        <Typography
                            onClick={() => (window.location.href = 'mailto:help@medihomelab.com')}
                        >
                            Email: help@medihomelab.com
                        </Typography>
                        <Divider />
                        <Typography
                            onClick={() => window.open('https://maps.app.goo.gl/pGxcyhze1vaf8UwF8', '_blank')}
                        >
                            Address: Naren Diagnostics & Healthcare, No. 78, Sahukar Chennaiah Road, Kuvempunagara North, TK Layout, Mysuru, Karnataka 570009, India
                        </Typography>
                        <Divider />
                        <Typography>MediHomeLab © 2024. All rights reserved.</Typography>
                    </Box>
                </Container>
                <BookNow title={title} open={open} onClose={handleClose} />
            </div>
        </div>
    );
};

export default Home;
