// MyDialog.js
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import BookingForm from './BookingForm';

const BookNow = ({ title, open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle align="center">Please fill the form and submit</DialogTitle>
            <DialogContent>
               <BookingForm testPackage={title}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default BookNow;
