import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Your Firebase configuration object
const firebaseConfig = {
    apiKey: "AIzaSyCG_bUDKXFwpbM2WHyrBbS96NFqQ1sOqOI",
    authDomain: "medi-home-lab.firebaseapp.com",
    projectId: "medi-home-lab",
    storageBucket: "medi-home-lab.appspot.com",
    messagingSenderId: "107056569281",
    appId: "1:107056569281:web:01d3c7ec523d25d875ad40"
  };

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

// Initialize Firestore
const db = getFirestore(app);

export { db, storage };
