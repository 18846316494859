import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BookingForm from './components/BookingForm';
import Admin from './components/Admin';
import AppBar from './components/AppBar';
import './styles/App.css';
import Home from './components/Home';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />          
          {/* Navigate to the Admin page if /admin is accessed */}
          {/* <Route path="/" element={<Admin />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
